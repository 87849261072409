<template>
  <div>
    <p class="subheading">Pares Biomagnéticos</p>
    <v-list class="elevation-1">
      <v-list-item
        v-for="(item, index) in pares"
        :key="index"
        class="py-2 item-par"
      >
        <v-list-item-avatar size="80" item class="mr-3">
          <v-img :src="getImageUrl(item.par.trackingPoint.image)" />
        </v-list-item-avatar>
        <v-list-item-avatar size="80" item class="mr-3">
          <v-img :src="getImageUrl(item.par.impactionPoint.image)" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            {{ item.par.name }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import { getFile } from "../../services/local/images-service";

export default {
  props: {
    pares: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    async getImageUrl(imageKey) {
      // Assuming imageKey is the key to retrieve the blob from IndexedDB
      const imageBlob = await getFile(imageKey);
      return URL.createObjectURL(imageBlob);
    },
  },
};
</script>

<style></style>
